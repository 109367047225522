export const createAccount = {
  methods: {
    associateAccount() {
      this.$swal({
        title: "Associate a New Account",
        icon: "info",
        input: "text",
        html:
          "<div><p>Please enter your PayPoint card number and Pin below</p></div>" +
          '<input id="swal-input1" class="swal2-input" type="text" placeholder="PayPoint Card">' +
          '<input id="swal-input2" class="swal2-input" type="text" placeholder="Pin">' +
          '<div id="swal-error"></div>',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const paypoint = document.getElementById("swal-input1").value;
          const pin = document.getElementById("swal-input2").value;

          return this.$store
            .dispatch("accountAssociate", { paypoint, pin })
            .then(response => {
              const title = "Account Linked";
              const subtitle =
                "You can now access your " + response.data.type + " account";
              this.$store.dispatch("pushSuccess", { title, subtitle });

              this.$router.push(
                "/" + response.data.type + "/" + response.data.id
              );
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was an error: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            });
        },
        inputValidator: () => {
          if (
            !document.getElementById("swal-input1").value ||
            !document.getElementById("swal-input2").value
          ) {
            return "Please enter your paypoint card and pin";
          }
        }
      });
    }
  }
};
