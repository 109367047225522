<template>
  <div>
    <h2>Manage your account</h2>

    <div>
      <div style="font-size: 2rem;">
        <b-icon-clipboard-plus size="lg" style="color: #8dc04c;"
          >Account</b-icon-clipboard-plus
        >
      </div>
      <h5>Add new Account</h5>

      <p>
        Add an additional apartment to your account using the PayPoint number
        and pin
      </p>

      <b-button
        @click="associateAccount"
        class="account-btn"
        size="sm"
        variant="outline-success"
        >Link New Account</b-button
      >
    </div>

    <div>
      <div style="font-size: 2rem;">
        <b-icon-lock-fill size="lg" style="color: #8dc04c;"
          >Account</b-icon-lock-fill
        >
      </div>
      <h5>Secure my Login</h5>
      <h6 class="item-state enabled" v-if="isMFA">Enabled</h6>
      <h6 class="item-state disabled" v-else>Disabled</h6>

      <p>
        Secure your login by requiring an e-mail code to be entered when
        accessing your account.
      </p>
      <p>More multi-factor authentication methods will be added soon.</p>

      <b-button
        v-if="isMFA"
        @click="updateMFA"
        class="btn-margin"
        size="sm"
        variant="danger"
        >Disable</b-button
      >
      <b-button
        v-else
        @click="updateMFA"
        class="account-btn"
        size="sm"
        variant="success"
        >Enable</b-button
      >
    </div>

    <div>
      <div style="font-size: 2rem;">
        <b-icon-pencil size="lg" style="color: #8dc04c;">Account</b-icon-pencil>
      </div>
      <h5>Change E-mail</h5>

      <p>Update your e-mail account associated with your account</p>

      <b-button
        @click="updateEmail"
        class="account-btn"
        size="sm"
        variant="outline-success"
        >Update</b-button
      >
    </div>
  </div>
</template>

<script>
import { BIconLockFill, BIconClipboardPlus, BIconPencil } from "bootstrap-vue";
import { createAccount } from "@/components/mixins/create-account";

export default {
  name: "AccountManagement",
  components: {
    BIconLockFill,
    BIconClipboardPlus,
    BIconPencil
  },
  computed: {
    isMFA() {
      return this.$store.state.auth.user.is_mfa === true;
    }
  },
  mixins: [createAccount],
  methods: {
    updateEmail() {
      this.$swal({
        title: "Update your e-mail",
        icon: "info",
        input: "text",
        html:
          "<div><p>This will change the e-mail you use to login to your account</p></div>" +
          '<input id="swal-input1" class="swal2-input" type="email" placeholder="New E-mail">' +
          '<input id="swal-input2" class="swal2-input" type="email" placeholder="Confirm New E-mail">' +
          '<input id="swal-input3" class="swal2-input" type="password" placeholder="Password">' +
          '<div id="swal-error"></div>',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const email = document.getElementById("swal-input1").value;
          const password = document.getElementById("swal-input3").value;

          return this.$store
            .dispatch("updateEmail", { email, password })
            .then(() => {
              const title = "E-mail updated";
              const subtitle =
                "We have successfully updated your account e-mail address";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            })
            .catch(err => {
              const title = "Invalid request";
              const subtitle = err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            });
        },
        inputValidator: () => {
          if (
            !document.getElementById("swal-input1").value ||
            !document.getElementById("swal-input2").value ||
            !document.getElementById("swal-input3").value
          ) {
            return "Please enter your new e-mail and your password";
          }

          if (
            document.getElementById("swal-input1").value !==
            document.getElementById("swal-input2").value
          ) {
            return "Your confirmed e-mail address does not match";
          }
        }
      });
    },
    updateMFA() {
      const isEnabled = !this.isMFA;
      let friendly = "Enable";

      if (!isEnabled) {
        friendly = "Disable";
      }

      this.$swal({
        title: friendly + " e-mail login security",
        icon: "info",
        input: "text",
        html:
          "<div><p>This will " +
          friendly +
          " e-mail login security. Enter your password below to confirm:</p></div>" +
          '<input id="swal-input1" class="swal2-input" type="password" placeholder="Password">' +
          '<div id="swal-error"></div>',
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const password = document.getElementById("swal-input1").value;

          return this.$store
            .dispatch("updateMFA", { isEnabled, password })
            .then(() => {
              const title = "E-mail login security updated";
              const subtitle =
                "E-mail login security has been successfully " + friendly + "d";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            })
            .catch(err => {
              const title = "Invalid request";
              const subtitle = err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            });
        },
        inputValidator: () => {
          if (!document.getElementById("swal-input1").value) {
            return "Please enter your password";
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.account-select {
  padding: 30px 0px;

  .icon {
    font-size: 3rem;
    color: $dePurple;
  }

  h2 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    margin-top: 15px;
    font-size: 1.3rem;
    color: #666;
  }

  h5 {
    font-size: 1.1rem;
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
  }
}

.account-dd {
  margin-top: 15px;
  margin-bottom: 25px;
  width: auto;
  max-width: 95%;
}

.account-icon {
  width: 70px;
  margin-top: 20px;
}

.btn.account-btn {
  border: 2px solid #8dc04c;
  background: #8dc04c;
  color: white;
  margin: 6px 6px 30px;
}

.btn-margin {
  margin: 6px 6px 30px;
}

.btn.account-danger-btn {
  border: 2px solid #ff5f50;
  background: #ffd7d3;
  color: white;
  margin: 6px 6px 30px;
}

.item-state {
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;

  &.enabled {
    color: #72aa29;
  }

  &.disabled {
    color: #ff5f50;
  }
}
</style>
